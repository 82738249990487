import { ReactElement } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

/**
 * A very simple and basic screen showing the link result
 */
export default function LinkResultMessage({
  isSuccess,
  message,
}: {
  isSuccess: boolean;
  message: string;
}): ReactElement {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center',
      }}
    >
      {isSuccess ? (
        <CheckCircleIcon color="success" sx={{ fontSize: '10rem' }} />
      ) : (
        <CancelIcon color="error" sx={{ fontSize: '10rem' }} />
      )}
      <Typography variant="h6">{message}</Typography>
    </Box>
  );
}
