import { ReactElement, useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import FinverseLinkWrapper from './components/finverse-link-wrapper';

export default function QuickStart(): ReactElement {
  const params = useParams();
  const [rawSearchParams] = useSearchParams();
  const searchParams = useMemo(() => {
    const params: Record<string, string> = {};

    const it = rawSearchParams.entries();
    let result = it.next();
    while (!result.done) {
      const [key, value] = result.value;
      params[key] = value;
      result = it.next();
    }
    return params;
  }, [rawSearchParams]);

  return <FinverseLinkWrapper appPath={params.appPath ?? ''} searchParams={searchParams} />;
}
