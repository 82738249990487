import { ReactElement } from 'react';

import { Box, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/finverse-logo.svg';

export default function Home(): ReactElement {
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    if (data.get('app_path')) navigate(data.get('app_path') as string);
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <div
        style={{
          width: '500px',
        }}
      >
        <img src={logo} alt="finverse-logo" />
      </div>
      <Typography component="h1" variant="h5">
        Home screen
      </Typography>
      <Box
        onSubmit={handleSubmit}
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
        }}
      >
        <TextField id="app_path" name="app_path" variant="outlined" margin="normal" fullWidth label="app_path" />
        <Button variant="contained" type="submit">
          Go
        </Button>
      </Box>
    </Box>
  );
}
