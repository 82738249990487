const iframeStyle = {
  border: 'none',
  display: 'block',
  height: '100%',
  width: '100%',
};

const iframeMobileStyle = {
  ...iframeStyle,
  zoom: '100%',
};

export default function LinkFrame({ url }: { url: string }): JSX.Element {
  const isMobile = (): boolean => {
    return (
      window.matchMedia('(max-width: 760px)').matches &&
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  };

  const isPortrait = window.innerWidth > window.innerHeight ? false : true;

  const IFrame = (): JSX.Element => <iframe style={iframeStyle} src={url} />;
  const IFrameMobile = (): JSX.Element => <iframe style={iframeMobileStyle} src={url} />;

  return <>{isMobile() && isPortrait ? <IFrameMobile /> : <IFrame />}</>;
}
