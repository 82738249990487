import { ReactElement } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './routes/index';
import theme from './styles/theme';

function App(): ReactElement {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgb(248, 249, 249)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1ch',
          }}
        >
          <AppRoutes />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
