import { ReactElement, useEffect } from 'react';

import LinkFrame from './components/link-frame';

const FinverseLink = ({
  link,
  successFn,
  failureFn,
}: {
  link: string;
  successFn: () => void;
  failureFn: () => void;
}): ReactElement => {
  useEffect(() => {
    const onMessage = (message: MessageEvent) => {
      if (message.data === 'success') successFn();
      // TODO: More helpful error later, with better callback
      if (message.data === 'failure') failureFn();
      // TODO: Should not a hard failure
      if (message.data === 'close') failureFn();
    };

    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [failureFn, successFn]);

  return <LinkFrame url={link} />;
};

export default FinverseLink;
