import { ReactElement } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import Home from '../features/home/index';
import QuickStart from '../features/quickstart/index';

const AppRoutes = (): ReactElement => (
  <Routes>
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="/app/">
        <Route index element={<Navigate replace to="/" />} />
        <Route path=":appPath" element={<QuickStart />} />
      </Route>
      <Route path="/:others" element={<Navigate replace to="/" />} />
    </Route>
  </Routes>
);

export default AppRoutes;
