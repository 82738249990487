import { useMemo } from 'react';

import axios, { AxiosError, AxiosResponse } from 'axios';
import useSWR from 'swr';

import config from '../../config';

interface FinverseLinkResponse {
  link_url: string;
}

const fetcher = async (url: string, params?: Record<string, string>) => {
  return await axios.get(url, {
    params: params,
  });
};

const parseErrorToMsg = (error: AxiosError | undefined): string | undefined => {
  if (!error) return undefined;
  if (error.response) {
    const response = error.response;
    if (response.status === 500) return response.statusText;
    if (response.status === 400) return response.data.error.message;
  }
  return 'Unknown Error';
};

export function useFinverseLink(
  appPath: string,
  params: Record<string, string> | undefined,
): {
  link: string | undefined;
  error: string | undefined;
  isLoading: boolean;
} {
  const baseUrl = config.apiHost;
  const url = useMemo(() => `${baseUrl}/app/${appPath}`, [baseUrl, appPath]);
  const { data, error } = useSWR<AxiosResponse<FinverseLinkResponse>, AxiosError>([url, params], fetcher, {
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });

  return {
    link: data?.data.link_url,
    error: parseErrorToMsg(error),
    isLoading: !error && !data,
  };
}
