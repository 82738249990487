type AppConfig = {
  env: string;
  apiHost: string;
};

export function getConfig(): AppConfig {
  const host = window.location.hostname;
  const env = getEnv(host);

  switch (env) {
    case 'prod':
      return {
        env: 'PROD',
        apiHost: 'https://quickstart.prod.finverse.net',
      };
    case 'sand':
      return {
        env: 'SAND',
        apiHost: 'https://quickstart.sandbox.finverse.net',
      };
    case 'dev2':
      return {
        env: 'DEV2',
        apiHost: 'https://quickstart.dev2.finverse.net',
      };
    case 'stg3':
      return {
        env: 'STG3',
        apiHost: 'https://quickstart.stg3.finverse.net',
      };
    case 'stg4':
      return {
        env: 'STG4',
        apiHost: 'https://quickstart.stg4.finverse.net',
      };
    default:
      return {
        env: 'LOCAL',
        apiHost: 'https://quickstart.sandbox.finverse.net',
      };
  }
}

function getEnv(url: string): string {
  if (url.includes('local')) return 'local';
  if (url.includes('dev2')) return 'dev2';
  if (url.includes('stg3')) return 'stg3';
  if (url.includes('stg4')) return 'stg4';
  if (url.includes('sand')) return 'sand';
  return 'prod';
}

export default getConfig();
