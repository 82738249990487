import { ReactElement, useState } from 'react';

import { CircularProgress } from '@mui/material';

import FinverseLink from '../../finverse-link/index';
import { useFinverseLink as useFinverseLinkUrl } from '../api';
import LinkResultMessage from './link-result-message';

enum LinkState {
  SUCCESS = 1,
  FAILURE = 2,
  INPROGRESS = 3,
}

const FinverseLinkWrapper = ({
  appPath,
  searchParams,
}: {
  appPath: string;
  searchParams?: Record<string, string>;
}): ReactElement => {
  const [linkState, setLinkState] = useState<LinkState>(LinkState.INPROGRESS);

  const { link, error: fetchError, isLoading } = useFinverseLinkUrl(appPath, searchParams);

  if (linkState === LinkState.SUCCESS) {
    return <LinkResultMessage isSuccess={true} message={'nice'} />;
  }
  if (fetchError || linkState === LinkState.FAILURE) {
    return <LinkResultMessage isSuccess={false} message={fetchError || 'Link failure'} />;
  }

  return (
    <>
      {isLoading || !link ? (
        <CircularProgress size="3rem" />
      ) : (
        <FinverseLink
          link={link}
          successFn={() => setLinkState(LinkState.SUCCESS)}
          failureFn={() => setLinkState(LinkState.FAILURE)}
        />
      )}
    </>
  );
};

export default FinverseLinkWrapper;
